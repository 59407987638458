import { type SVGProps } from 'react';

export const CirclesGraphics = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 258 258" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={129} cy={129} r={127.5} stroke="#FFCF00" strokeWidth={3} />
    <circle cx={129} cy={129} r={108.5} stroke="#FFCF00" strokeWidth={3} />
    <circle cx={129} cy={129} r={84.5} stroke="#FFCF00" strokeWidth={3} />
    <circle cx={129} cy={129} r={61.5} stroke="#FFCF00" strokeWidth={3} />
  </svg>
);
