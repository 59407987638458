import {
  GENERIC_ERROR_MESSAGE_FALLBACK,
  GENERIC_ERROR_STATUS,
  GENERIC_ERROR_TRANSLATION_KEY,
  logException,
} from '@shared/utils';

import { invalidateProfilePhotoTag } from '../helpers';
import { getPublicEnvironmentVariables } from '../helpers/get-public-environment-variables';
import { type MutationError } from '../types';

export const postProfilePicture = async ({
  abortSignal,
  accessToken,
  personId,
  formData,
}: {
  abortSignal: AbortSignal;
  accessToken: string | undefined;
  formData: BodyInit;
  personId: number | string | undefined;
}): Promise<Response | null | MutationError> => {
  const publicEnvironmentVariables = await getPublicEnvironmentVariables();

  try {
    const response = await fetch(
      `${publicEnvironmentVariables.apiBaseUrl}/${publicEnvironmentVariables.apiRoute}/CustomerProfile/${personId}/ProfilePicture`,
      {
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Ocp-Apim-Subscription-Key': publicEnvironmentVariables.ocpApiSubscriptionKey ?? '',
        },
        method: 'POST',
        signal: abortSignal,
      }
    );

    if (response.status !== 200) {
      const resolvedResponse = await response.json();

      return {
        message: resolvedResponse.message || response.statusText,
        messageTranslationKey: resolvedResponse.messageTranslationKey || '',
        status: response.status,
      };
    }

    invalidateProfilePhotoTag(personId);

    return response;
  } catch (error) {
    logException(error);

    if (abortSignal.aborted) {
      return null;
    }

    return {
      message: GENERIC_ERROR_MESSAGE_FALLBACK,
      messageTranslationKey: GENERIC_ERROR_TRANSLATION_KEY,
      status: GENERIC_ERROR_STATUS,
    };
  }
};
