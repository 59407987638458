import * as React from 'react';
import { type SVGProps } from 'react';

export const SvgNumber04 = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 125 81" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M93.103 77.112v1h20.408V65.84h9.568V49.384h-9.568V2.792H96.594l-.299.433-32.76 47.632-.176.256V65.84h29.744zm-10.33-27.728 10.33-15.375v15.375zM8.364 68.96l.003.004c4.996 6.878 12.249 10.292 21.609 10.292s16.613-3.414 21.61-10.292l.002-.004c4.953-6.891 7.364-16.557 7.364-28.872 0-12.242-2.41-21.745-7.374-28.366-5-6.737-12.251-10.074-21.602-10.074-9.35 0-16.602 3.337-21.602 10.074C3.41 18.344 1 27.882 1 40.192 1 52.439 3.412 62.07 8.364 68.96Zm28.085-11.708-.003.008c-.747 1.688-1.665 2.881-2.719 3.653-1.043.765-2.28 1.159-3.751 1.159s-2.719-.395-3.782-1.163c-1.072-.774-2.014-1.968-2.793-3.654-1.506-3.477-2.305-9.159-2.305-17.167 0-7.952.802-13.443 2.295-16.627.777-1.616 1.715-2.763 2.787-3.508 1.065-.739 2.318-1.121 3.798-1.121s2.722.382 3.766 1.118c1.053.741 1.967 1.885 2.71 3.5l.005.011.005.01c1.556 3.178 2.394 8.663 2.394 16.617 0 8.014-.835 13.693-2.407 17.164Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
