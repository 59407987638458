import * as React from 'react';
import { type SVGProps } from 'react';

export const SvgNumber03 = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 121 80" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m74.884 76.74.013.004q7.318 2.121 15.254 2.12c9.075 0 16.317-1.933 21.61-5.921l.002-.002c5.39-4.079 8.092-9.648 8.092-16.605 0-4.615-1.192-8.606-3.621-11.921-1.911-2.686-4.469-4.683-7.64-6.001a16.9 16.9 0 0 0 6.001-5.552c1.99-3.022 2.972-6.51 2.972-10.43 0-6.585-2.405-11.812-7.248-15.556-4.811-3.79-11.437-5.62-19.752-5.62q-7.532 0-14.546 2.23c-4.609 1.418-8.542 3.45-11.777 6.11l-.578.475.293.688 5.928 13.936.513 1.207 1.028-.814c5.169-4.095 10.914-6.128 17.267-6.128 3.134 0 5.305.57 6.67 1.553 1.304.94 2.002 2.352 2.002 4.415 0 2.074-.742 3.558-2.184 4.596l-.008.006-.008.006c-1.43 1.073-3.512 1.672-6.368 1.672H76.88v17.183999999999997h13.792c3.24 0 5.492.58 6.91 1.584 1.355.96 2.074 2.397 2.074 4.488 0 2.286-.812 3.878-2.373 4.947-1.629 1.116-4.198 1.749-7.859 1.749-7 0-13.422-2.049-19.29-6.163l-1.025-.719-.474 1.16-5.824 14.248-.278.68.565.468c2.974 2.466 6.919 4.425 11.787 5.907ZM8.364 68.568l.003.004c4.996 6.878 12.249 10.292 21.609 10.292s16.613-3.414 21.61-10.292l.002-.004c4.953-6.891 7.364-16.557 7.364-28.872 0-12.242-2.41-21.745-7.374-28.366-5-6.737-12.251-10.074-21.602-10.074-9.35 0-16.602 3.337-21.602 10.074C3.41 17.952 1 27.49 1 39.8c0 12.247 2.412 21.878 7.364 28.768ZM36.449 56.86l-.003.008c-.747 1.688-1.665 2.881-2.719 3.653-1.043.764-2.28 1.159-3.751 1.159s-2.719-.395-3.782-1.163c-1.072-.774-2.014-1.968-2.793-3.654-1.506-3.477-2.305-9.16-2.305-17.167 0-7.952.802-13.443 2.295-16.627.777-1.616 1.715-2.763 2.787-3.508 1.065-.739 2.318-1.121 3.798-1.121s2.722.382 3.766 1.118c1.053.74 1.967 1.885 2.71 3.5l.005.01.005.012c1.556 3.177 2.394 8.662 2.394 16.616 0 8.014-.835 13.693-2.407 17.164Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
