import classnames from 'classnames';

import { RenderHTML } from '@shared/ui-components';

import { Separator } from '../enums/separator';

import styles from './rich-text-block.module.scss';

type RichTextBlockProps = {
  className?: string;
  heading?: string;
  html: string;
  id?: Nullable<string>;
  noParagraphGutters?: boolean;
  onDark?: boolean;
  separator?: string;
  standalone?: boolean;
};

export const RichTextBlock = ({
  className,
  heading,
  html,
  id,
  noParagraphGutters,
  onDark = false,
  separator,
  standalone,
}: RichTextBlockProps) => {
  return standalone ? (
    <section
      className={classnames(styles['standalone-section'], {
        [styles['standalone-section--separator-bottom']]:
          separator === Separator.Bottom || separator === Separator.Both,
        [styles['standalone-section--separator-top']]:
          separator === Separator.Top || separator === Separator.Both,
      })}
      {...(id && { id })}
    >
      {heading && <h2>{heading}</h2>}
      <RenderHTML
        className={classnames(
          styles['html-block'],
          {
            [styles['html-block--on-dark']]: onDark,
            [styles['html-block--standalone']]: standalone,
            [styles['html-block--no-paragraph-gutters']]: noParagraphGutters,
          },
          className
        )}
        html={html}
      />
    </section>
  ) : (
    <RenderHTML
      className={classnames(
        styles['html-block'],
        {
          [styles['html-block--on-dark']]: onDark,
          [styles['html-block--no-paragraph-gutters']]: noParagraphGutters,
        },
        className
      )}
      html={html}
      {...(id && { id })}
    />
  );
};
