import Skeleton from 'react-loading-skeleton';

import { useMappedIntlLocale } from '../../../../utils/hooks/use-mapped-intl-locale';
import { type MembershipRequirementsPerYear } from '../../../../utils/types';

export const useDataFieldRenderer = ({
  basedOnSelectedCountry = true,
  countryYearData,
  isFetching,
}: {
  basedOnSelectedCountry?: boolean;
  countryYearData?: MembershipRequirementsPerYear;
  isFetching: boolean;
}) => {
  const locale = useMappedIntlLocale();
  const numberFormatter = new Intl.NumberFormat(locale);

  const renderDataField = (value: number | string | undefined) => {
    if (isFetching) {
      return <Skeleton style={{ position: 'relative', zIndex: -1 }} />;
    }

    if (!value) {
      return undefined;
    }

    if (basedOnSelectedCountry) {
      if (!countryYearData?.currencyCode) {
        return undefined;
      }

      return `${numberFormatter.format(Number(value))} ${countryYearData?.currencyCode}`;
    }

    return `${numberFormatter.format(Number(value))} USD`;
  };

  return {
    renderDataField,
  };
};
