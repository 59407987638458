import Skeleton from 'react-loading-skeleton';

import { CarouselSkeleton } from '@module/mdrt-org/shared/components';

import styles from './learn-content-carousel-skeleton.module.scss';

export const LearnContentCarouselSkeleton = () => {
  return (
    <CarouselSkeleton>
      {[1, 2, 3, 4, 6, 7, 8, 9, 10].map((key) => (
        <div className={styles['card-skeleton']} key={key}>
          <Skeleton borderRadius={16} />
        </div>
      ))}
    </CarouselSkeleton>
  );
};
