import { ChipTheme } from '@shared/ui-components';

import { Chip, ScrollableContainer } from '../../../../../../components';
import { type useCarouselSourceFilter } from '../../hooks/use-carousel-source-filter';

import styles from './source-filter-controls.module.scss';

export const SourceFilterControls = ({
  filterState,
}: {
  filterState: ReturnType<typeof useCarouselSourceFilter>;
}) => {
  const { setCurrentSourceIdFilter, sourceFilterItems } = filterState;

  return (
    <ScrollableContainer>
      {sourceFilterItems && sourceFilterItems.length > 2 && (
        <ul className={styles['filter']}>
          {sourceFilterItems.map((sourceFilterItem) => (
            <li key={sourceFilterItem.value}>
              <Chip
                aria-current={sourceFilterItem.selected}
                className={styles['filter__item']}
                label={sourceFilterItem.label ?? ''}
                onClick={() => setCurrentSourceIdFilter(sourceFilterItem.value)}
                theme={sourceFilterItem.selected ? ChipTheme.Active : ChipTheme.Light}
              />
            </li>
          ))}
        </ul>
      )}
    </ScrollableContainer>
  );
};
