import { type SelectOption } from '@shared/ui-components';

import { DEFAULT_COUNTRY } from '../constants/default-country';

export const getDefaultCountryOption = ({
  countryOptions,
  userCountry,
}: {
  countryOptions: Array<SelectOption<string>>;
  userCountry: { countryCode: string; countryName: string };
}) => {
  const { countryCode, countryName } = userCountry;
  const attemptToFind = (matchClause: (option: SelectOption<string>) => boolean) =>
    countryOptions.find(matchClause);

  return (
    // strict match by country code and name
    attemptToFind((option) => option?.value === countryCode && option?.label === countryName) ||
    // loose match by country code
    attemptToFind((option) => option?.value === countryCode) ||
    // strict match with predefined default country
    attemptToFind(
      (option) =>
        option?.value === DEFAULT_COUNTRY.countryCode &&
        option?.label === DEFAULT_COUNTRY.countryName
    ) ||
    // loose match with predefined default country
    attemptToFind((option) => option?.value === DEFAULT_COUNTRY.countryCode) ||
    // last resort - first country in the list
    countryOptions[0]
  );
};
