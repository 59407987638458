import { type UseMutateFunction, useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';

import { ToastType, useGlobalToastContext } from '@shared/ui-components';
import { type ServerActionResponse } from '@shared/utils';

import { useGlobalCmsContentContext } from '../../components';
import { NOT_AUTHORIZED_PATH } from '../constants/local-paths';
import { type MutationError } from '../types/mutation-error';

export type MutationFunction<T> = (
  args: T
) => Promise<Response | ServerActionResponse | MutationError | null>;

export type Mutate<T> = UseMutateFunction<ServerActionResponse | Response, Error, T, unknown>;

type MutationHandlerArgs<T> = {
  mutationFunction: MutationFunction<T>;
  mutationKey?: string[];
  onFailure?: (result: ServerActionResponse) => void;
  onMutate?: (variables: unknown) => Promise<unknown> | unknown;
  onSuccessHandler?: () => void;
  scrollToTop?: boolean;
  showSuccessToast?: boolean;
  successMessage?: string;
};

export const useMutationHandler = <T>({
  mutationFunction,
  onFailure,
  successMessage,
  scrollToTop = true,
  showSuccessToast = true,
  mutationKey,
  onMutate,
  onSuccessHandler,
}: MutationHandlerArgs<T>) => {
  const { addToast } = useGlobalToastContext();
  const { cmsContent } = useGlobalCmsContentContext();
  const router = useRouter();

  const { isPending: isMutationPending, mutate } = useMutation({
    mutationFn: mutationFunction,
    mutationKey,
    onMutate,
    onSuccess: (result) => {
      if (result === null) return;

      if (result?.status === 200 && showSuccessToast) {
        if (onSuccessHandler) onSuccessHandler();

        addToast(
          {
            description: successMessage ?? cmsContent.successMessage,
            isCloseButtonVisible: false,
            type: ToastType.SUCCESS,
          },
          { timeout: 3000 }
        );
      }

      if (!onFailure && result && 'message' in result) {
        addToast(
          {
            closeButtonLabel: cmsContent.closeButtonLabel,
            description: cmsContent.somethingWentWrong,
            type: ToastType.ERROR,
          },
          { timeout: 0 }
        );
      }

      onFailure?.(result);

      if (scrollToTop) {
        setTimeout(() => {
          window.scrollTo({ behavior: 'smooth', top: 0 });
        }, 0);
      }

      if (result?.status === 401) {
        router.push(NOT_AUTHORIZED_PATH);
      }
    },
  });

  return { isMutationPending, mutate };
};
