import Skeleton from 'react-loading-skeleton';

import {
  type MembershipRequirementBlockLinkBlock,
  type MembershipRequirementBlock,
  type MembershipRequirementsBlock,
  type KeyLinkValueBlock,
  type KeyLinkValueBlockLinkBlock,
} from '../../../../utils/data/graphql/_generated/gql-generated';
import { type MembershipRequirementsPerYear } from '../../../../utils/types';
import { LinkBlock } from '../../../link-block';
import { RichTextBlock } from '../../../rich-text-block';

import styles from './additional-requirements.module.scss';

type AdditionalRequirementsProps = {
  blockData?: MembershipRequirementsBlock;
  countryYearData?: MembershipRequirementsPerYear;
  isFetching?: boolean;
  yearBlockData?: MembershipRequirementBlock;
};

export const AdditionalRequirements = ({
  blockData,
  countryYearData,
  isFetching,
  yearBlockData,
}: AdditionalRequirementsProps) => {
  const renderAdditionalRequirementsLink = (
    linkBlockData?: MembershipRequirementBlockLinkBlock | null
  ) => {
    if (!linkBlockData?.LinkToContent) {
      return undefined;
    }

    return <LinkBlock blockData={linkBlockData} />;
  };

  const renderMonthlyGoalChartLink = (linkBlockData?: KeyLinkValueBlockLinkBlock | null) => {
    if (!linkBlockData?.LinkToContent) {
      return undefined;
    }

    return (
      <LinkBlock
        blockData={{
          ...linkBlockData,
          DisplayText: blockData?.MonthlyGoalChartsLinkText || linkBlockData.DisplayText,
        }}
      />
    );
  };

  const relevantMonthlyGoalChart = yearBlockData?.MonthlyGoalCharts?.find(
    (monthlyGoalChart) =>
      (monthlyGoalChart?.ContentLink?.Expanded as KeyLinkValueBlock | undefined)?.Name ===
      countryYearData?.country
  );

  const monthlyGoalChartContent = relevantMonthlyGoalChart?.ContentLink?.Expanded as
    | KeyLinkValueBlock
    | undefined;

  return (
    <div className={styles.requirements}>
      {isFetching ? (
        <Skeleton className={styles.skeleton} />
      ) : (
        <>
          {countryYearData?.showProductionFooter ? (
            <div className={styles['requirements__note-wrapper']}>
              <RichTextBlock
                className={styles['requirements__note-wrapper--block']}
                html={yearBlockData?.SpecificProductionFooterText ?? ''}
              />
              {countryYearData.unlimitedProductionNotRequired
                ? renderAdditionalRequirementsLink(yearBlockData?.NonCoreDownloadRequirementsLink)
                : renderAdditionalRequirementsLink(yearBlockData?.DownloadRequirementsLink)}
            </div>
          ) : (
            <div className={styles['requirements__note-wrapper']}>
              <RichTextBlock
                className={styles['requirements__note-wrapper--block']}
                html={yearBlockData?.GenericProductionFooterText ?? ''}
              />
              {countryYearData?.unlimitedProductionNotRequired
                ? renderAdditionalRequirementsLink(yearBlockData?.NonCoreDownloadRequirementsLink)
                : renderAdditionalRequirementsLink(yearBlockData?.DownloadRequirementsLink)}
            </div>
          )}
          <div className={styles['requirements__conversion-factors-wrapper']}>
            <h3>{blockData?.ConversionFactorsStepLabel}</h3>
            <RichTextBlock
              className={styles['requirements__conversion-factors-wrapper--block']}
              html={yearBlockData?.ConversionFactorsDescription ?? ''}
              noParagraphGutters
            />
          </div>
          {countryYearData?.showMonthlyGoalChart && (
            <div className={styles['requirements__monthly-goals-wrapper']}>
              <h3>{blockData?.MonthlyGoalStepName}</h3>
              <RichTextBlock html={blockData?.ProductionRequirementsMonthlyGoalDescription ?? ''} />
              {renderMonthlyGoalChartLink(monthlyGoalChartContent?.Value)}
            </div>
          )}
        </>
      )}
    </div>
  );
};
