import { BadgeCot, BadgeMember, BadgeTot } from '@shared/ui-components';

import { MembershipLevel } from '../../../../utils/enums/membership';

import styles from './membership-level-label.module.scss';

const membershipLevelBadgeMap = {
  [MembershipLevel.MDRT]: BadgeMember,
  [MembershipLevel.TOT]: BadgeTot,
  [MembershipLevel.COT]: BadgeCot,
};

export const MembershipLevelLabel = ({
  label,
  membershipLevel,
}: {
  label: string;
  membershipLevel: MembershipLevel;
}) => {
  const Badge = membershipLevelBadgeMap[membershipLevel];

  return (
    <span className={styles['label']}>
      <Badge aria-label={label} className={styles['label__badge']} />
      <span>{label}</span>
    </span>
  );
};
