import { useMappedIntlLocale } from '../../../../utils/hooks/use-mapped-intl-locale';
import { type MembershipRequirementDimensions } from '../../../../utils/types';

export const useMembershipRequirementsOptions = (dimensions: MembershipRequirementDimensions) => {
  const locale = useMappedIntlLocale();

  const formatter = new Intl.DateTimeFormat(locale, { year: 'numeric' });

  const countryOptions =
    dimensions?.countries?.map(({ code, name }) => ({
      label: name,
      value: code,
    })) ?? [];

  const yearOptions = dimensions?.years
    ?.sort((yearA, yearB) => yearA - yearB)
    .map((year) => ({
      label: formatter.format(new Date(year, 0)),
      value: year,
    }));

  return { countryOptions, yearOptions };
};
