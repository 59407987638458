import classnames from 'classnames';

import { ArrowRightIcon, ButtonVariant, ExternalLinkIcon } from '@shared/ui-components';

import { Button } from '../../components';
import {
  type MembershipRequirementBlockLinkBlock,
  type ImageTextCardBlockLinkBlock,
  type LinkBlock as LinkBlockData,
  type KeyLinkValueBlockLinkBlock,
  type NextCallOutBlockLinkBlock,
} from '../../utils/data/graphql/_generated/gql-generated';

import styles from './link-block.module.scss';

export enum LinkBlockStyle {
  PrimaryButton = 'next-button-primary',
  SecondaryButton = 'next-button-secondary',
  TextIcon = 'next-text-icon',
  TextSmall = 'next-text-small',
}

type LinkBlockProps = {
  blockData:
    | LinkBlockData
    | ImageTextCardBlockLinkBlock
    | MembershipRequirementBlockLinkBlock
    | KeyLinkValueBlockLinkBlock
    | NextCallOutBlockLinkBlock;
  className?: string;
  linkStyleOverride?: LinkBlockStyle;
  onDark?: boolean;
};

export const LinkBlock = ({
  blockData,
  className,
  linkStyleOverride,
  onDark = false,
}: LinkBlockProps) => {
  const styleToRender = linkStyleOverride
    ? linkStyleOverride
    : (blockData?.LinkStyle as LinkBlockStyle);

  // TODO: we need a more reliable way to determine if link is internal / external
  const isExternal = blockData?.Target === '_blank';

  const commonProps = {
    ...(blockData.DownloadFileName && { download: blockData.DownloadFileName ?? '' }),
    ...(blockData.Target && { target: blockData.Target ?? '' }),
    children: blockData?.DisplayText,
    isInverted: onDark,
    to: blockData?.LinkToContent ?? '',
  };

  switch (styleToRender) {
    case 'next-button-primary':
      return (
        <Button
          className={classnames(className, styles['button'])}
          isExternal={isExternal}
          {...commonProps}
        />
      );
    case 'next-button-secondary':
      return (
        <Button
          className={classnames(className, styles['button'])}
          isExternal={isExternal}
          variant={ButtonVariant.Secondary}
          {...commonProps}
        />
      );
    case 'next-text-icon':
      return (
        <Button
          className={classnames(className)}
          isExternal={isExternal}
          suffix={
            isExternal ? (
              <ExternalLinkIcon />
            ) : (
              <ArrowRightIcon className={styles['internal-icon']} />
            )
          }
          variant={ButtonVariant.Tertiary}
          {...commonProps}
        />
      );
    case 'next-text-small':
    default:
      return (
        <Button
          className={classnames(className)}
          isExternal={isExternal}
          isSmall
          variant={ButtonVariant.Tertiary}
          {...commonProps}
        />
      );
  }
};
