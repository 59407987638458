export const isMobileUserAgent = (userAgent: string): boolean => {
  const isIpad =
    userAgent.toLowerCase().includes('macintosh') &&
    Boolean(navigator.maxTouchPoints) &&
    navigator.maxTouchPoints > 2;

  // eslint-disable-next-line require-unicode-regexp, regexp/no-unused-capturing-group
  const isMobileDevice = /android|ip(hone|[ao]d)/i.test(userAgent);
  return isMobileDevice || isIpad;
};
