import * as React from 'react';
import { type SVGProps } from 'react';

export const SvgNumber08 = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 123 80" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m70.733 73.012.005.004c5.358 3.91 12.593 5.808 21.597 5.808 9.002 0 16.205-1.897 21.496-5.81 5.401-3.942 8.104-9.45 8.104-16.406 0-4.398-1.083-8.24-3.29-11.479-1.816-2.723-4.119-4.827-6.899-6.301 2.237-1.489 4.063-3.477 5.477-5.948 1.834-3.084 2.736-6.625 2.736-10.592 0-6.537-2.527-11.73-7.578-15.465-5.01-3.775-11.727-5.607-20.046-5.607S77.3 3.048 72.29 6.823c-5.05 3.734-7.578 8.928-7.578 15.465 0 3.839.908 7.312 2.741 10.391 1.478 2.483 3.37 4.497 5.674 6.03-2.847 1.473-5.214 3.579-7.094 6.308l-.003.004c-2.21 3.242-3.294 7.121-3.294 11.587 0 6.952 2.665 12.46 7.998 16.404ZM98.54 19.505l.009.008.01.007c1.406 1.173 2.135 2.782 2.135 4.952 0 2.165-.727 3.818-2.145 5.063-1.43 1.188-3.462 1.841-6.215 1.841-2.747 0-4.776-.65-6.206-1.834-1.35-1.24-2.05-2.895-2.05-5.07 0-2.185.706-3.798 2.049-4.965l.002-.002c1.43-1.252 3.458-1.937 6.205-1.937 2.748 0 4.775.685 6.206 1.937ZM84.493 49.83l.01-.007.008-.007c1.7-1.275 4.26-1.984 7.824-1.984 3.686 0 6.27.682 7.911 1.886 1.583 1.162 2.425 2.913 2.425 5.434s-.842 4.272-2.425 5.434c-1.642 1.205-4.225 1.886-7.91 1.886-3.566 0-6.131-.675-7.834-1.887C82.85 59.342 82 57.578 82 55.152c0-2.43.853-4.15 2.494-5.322ZM8.364 68.528l.003.004c4.996 6.878 12.249 10.292 21.609 10.292s16.613-3.414 21.61-10.292l.002-.004c4.953-6.891 7.364-16.557 7.364-28.872 0-12.242-2.41-21.745-7.374-28.366-5-6.737-12.251-10.074-21.602-10.074-9.35 0-16.602 3.337-21.602 10.074C3.41 17.912 1 27.45 1 39.76c0 12.247 2.412 21.878 7.364 28.768Zm28.085-11.709-.003.008c-.747 1.689-1.665 2.882-2.719 3.654-1.043.764-2.28 1.159-3.751 1.159s-2.719-.395-3.782-1.163c-1.072-.774-2.014-1.968-2.793-3.654-1.506-3.477-2.305-9.16-2.305-17.167 0-7.952.802-13.443 2.295-16.627.777-1.616 1.715-2.763 2.787-3.508 1.065-.739 2.318-1.121 3.798-1.121s2.722.382 3.766 1.118c1.053.741 1.967 1.885 2.71 3.5l.005.01.005.012c1.556 3.177 2.394 8.662 2.394 16.616 0 8.014-.835 13.693-2.407 17.164Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
