import { type ReactNode } from 'react';

import { LearnContentCarouselSkeleton } from './components';

import { CAROUSELS_PER_PAGE } from './constants';

export const LearnCarouselContentPageSkeleton = () => {
  return Array.from({ length: CAROUSELS_PER_PAGE }).fill(
    <LearnContentCarouselSkeleton />
  ) as ReactNode[];
};
