import { RenderHTML } from '@shared/ui-components';

import { type MembershipRequirementsBlock } from '../../../../utils/data/graphql/_generated/gql-generated';

import styles from './ethical-code.module.scss';

type EthicalCodeProps = {
  blockData?: MembershipRequirementsBlock;
};

export const EthicalCode = ({ blockData }: EthicalCodeProps) => (
  <section
    className={styles.wrapper}
    {...(blockData?.EthicalCodeAnchorLink && {
      id: blockData.EthicalCodeAnchorLink,
    })}
  >
    <h2 className={styles['wrapper__label']}>{blockData?.EthicalCodeStepLabel}</h2>
    <RenderHTML html={blockData?.EthicalCodeDescription} />
  </section>
);
