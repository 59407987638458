import * as React from 'react';
import { type SVGProps } from 'react';

export const SvgNumber10 = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 121 80" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m68.603 68.312.003.004c4.996 6.878 12.25 10.292 21.61 10.292s16.612-3.414 21.608-10.292l.003-.004c4.953-6.891 7.364-16.557 7.364-28.872 0-12.242-2.409-21.745-7.374-28.366C106.817 4.337 99.566 1 90.215 1c-9.35 0-16.602 3.337-21.601 10.074-4.966 6.622-7.375 16.16-7.375 28.47 0 12.247 2.412 21.878 7.364 28.768Zm28.085-11.708-.003.008c-.747 1.688-1.665 2.881-2.719 3.653-1.043.764-2.279 1.159-3.75 1.159s-2.72-.395-3.783-1.163c-1.072-.774-2.014-1.968-2.793-3.654-1.506-3.477-2.305-9.16-2.305-17.167 0-7.952.803-13.443 2.296-16.627.776-1.616 1.715-2.763 2.787-3.508 1.064-.739 2.317-1.121 3.797-1.121s2.722.382 3.766 1.118c1.053.74 1.967 1.885 2.71 3.5l.005.01.005.012c1.556 3.177 2.394 8.662 2.394 16.616 0 8.014-.835 13.693-2.407 17.164ZM5.016 76.464v1h52.648V59.968H42.376V2.144h-14.68l-.229.13-24.96 14.144-.828.47.428.85L9.075 31.57l.474.941.915-.523 10.88-6.217v34.197H5.016v16.496000000000002Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
