import * as React from 'react';
import { type SVGProps } from 'react';

export const SvgNumber09 = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 122 81" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m73.705 77.29.01.003.01.003a39.5 39.5 0 0 0 12.162 1.92c7.217 0 13.437-1.557 18.615-4.721 5.244-3.161 9.221-7.722 11.933-13.642 2.779-5.918 4.148-12.967 4.148-21.117 0-11.99-2.662-21.397-8.127-28.086-5.422-6.722-13.15-10.042-23.033-10.042-5.076 0-9.619 1.145-13.6 3.46-3.967 2.237-7.11 5.345-9.416 9.31l-.007.011c-2.245 3.983-3.353 8.56-3.353 13.699 0 4.716.966 8.968 2.92 12.733 1.947 3.748 4.59 6.724 7.934 8.905l.01.007.011.006c3.425 2.113 7.248 3.165 11.445 3.165 3.83 0 7.265-.868 10.277-2.63a17.3 17.3 0 0 0 5.067-4.349c-.328 4.928-1.567 8.6-3.614 11.12-2.38 2.932-5.983 4.467-11.002 4.467-5.726 0-10.99-1.858-15.818-5.613l-1.025-.798-.509 1.196-5.928 13.936-.293.69.58.475q4.717 3.859 10.603 5.891Zm22.459-43.314-.002.001c-1.38 1.56-3.242 2.367-5.699 2.367-2.449 0-4.35-.802-5.803-2.368-1.398-1.649-2.14-3.857-2.14-6.72 0-2.873.747-5.042 2.14-6.617 1.457-1.64 3.36-2.471 5.803-2.471s4.303.831 5.687 2.457l.013.015.014.014c1.454 1.576 2.23 3.739 2.23 6.602 0 2.862-.775 5.069-2.243 6.72ZM8.364 68.92l.003.004c4.996 6.878 12.249 10.292 21.609 10.292s16.613-3.414 21.61-10.292l.002-.004c4.953-6.891 7.364-16.557 7.364-28.872 0-12.242-2.41-21.745-7.374-28.366-5-6.737-12.251-10.074-21.602-10.074-9.35 0-16.602 3.337-21.602 10.074C3.41 18.304 1 27.842 1 40.152 1 52.399 3.412 62.03 8.364 68.92Zm28.085-11.708-.003.007c-.747 1.689-1.665 2.882-2.719 3.654-1.043.765-2.28 1.159-3.751 1.159s-2.719-.394-3.782-1.163c-1.072-.774-2.014-1.968-2.793-3.654-1.506-3.477-2.305-9.16-2.305-17.167 0-7.952.802-13.443 2.295-16.627.777-1.616 1.715-2.763 2.787-3.508 1.065-.739 2.318-1.121 3.798-1.121s2.722.382 3.766 1.118c1.053.74 1.967 1.885 2.71 3.5l.005.01.005.012c1.556 3.177 2.394 8.662 2.394 16.616 0 8.014-.835 13.692-2.407 17.164Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
