import * as React from 'react';
import { type SVGProps } from 'react';

export const SvgNumber06 = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 124 81" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m72.109 68.996.004.005c5.49 6.718 13.25 10.039 23.134 10.039 5.14 0 9.716-1.108 13.699-3.353l.012-.007c3.966-2.307 7.075-5.451 9.312-9.421 2.245-3.983 3.353-8.56 3.353-13.699 0-4.716-.966-8.968-2.92-12.733-1.948-3.751-4.631-6.696-8.048-8.807-3.359-2.188-7.152-3.276-11.352-3.276-3.831 0-7.299.87-10.376 2.628l-.009.005a17.1 17.1 0 0 0-4.845 4.196c.34-4.854 1.568-8.477 3.58-10.973 2.363-2.93 5.94-4.464 10.922-4.464q4.089-.001 8.094 1.4l.007.002c2.735.934 5.345 2.338 7.832 4.22l1.031.78.496-1.195 5.824-14.04.285-.688-.578-.47c-3.081-2.509-6.622-4.403-10.613-5.687a37.7 37.7 0 0 0-12.17-2.026c-7.15 0-13.368 1.559-18.612 4.72s-9.256 7.72-12.037 13.639l-.004.009c-2.708 5.915-4.043 12.963-4.043 21.112 0 11.988 2.628 21.395 8.022 28.084Zm27.799-8.884-.002.001c-1.38 1.56-3.242 2.367-5.699 2.367-2.375 0-4.248-.799-5.707-2.375-1.463-1.65-2.237-3.855-2.237-6.713 0-2.862.776-5.068 2.244-6.72 1.463-1.646 3.334-2.472 5.7-2.472 2.444 0 4.303.831 5.687 2.456l.007.008.007.008c1.467 1.652 2.243 3.858 2.243 6.72s-.776 5.069-2.243 6.72ZM8.364 68.744l.003.004c4.996 6.878 12.249 10.292 21.609 10.292s16.613-3.414 21.61-10.292l.002-.004c4.953-6.891 7.364-16.557 7.364-28.872 0-12.242-2.41-21.745-7.374-28.366-5-6.737-12.251-10.074-21.602-10.074-9.35 0-16.602 3.337-21.602 10.074C3.41 18.128 1 27.666 1 39.976c0 12.247 2.412 21.878 7.364 28.768Zm28.085-11.708-.003.008c-.747 1.688-1.665 2.881-2.719 3.653-1.043.764-2.28 1.159-3.751 1.159s-2.719-.395-3.782-1.163c-1.072-.774-2.014-1.968-2.793-3.654-1.506-3.477-2.305-9.159-2.305-17.167 0-7.952.802-13.443 2.295-16.627.777-1.616 1.715-2.763 2.787-3.508 1.065-.739 2.318-1.121 3.798-1.121s2.722.382 3.766 1.118c1.053.741 1.967 1.885 2.71 3.5l.005.011.005.01c1.556 3.178 2.394 8.663 2.394 16.617 0 8.014-.835 13.693-2.407 17.164Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
