'use client';

import classnames from 'classnames';
import { useLocale } from 'next-intl';

import { type LearnGenericTextsCmsContent } from '@module/mdrt-org/shared/block-components/learn-carousel-block/graphql/get-learn-generic-texts-cms-content';
import { CardLink } from '@module/mdrt-org/shared/components';
import { DEFAULT_LOCALE } from '@module/mdrt-org/shared/i18n';
import { type LearnContentCarouselCardFragment } from '@module/mdrt-org/shared/utils/data/graphql/_generated/gql-generated';
import {
  getCardLinkDescription,
  getContentFallbackImageSource,
  getContentTypeIconComponent,
  getTextFromHtmlString,
  getFeaturedPeopleOrAuthors,
  convertSecondsToMinutes,
} from '@module/mdrt-org/shared/utils/helpers';
import { Badge, RenderHTML } from '@shared/ui-components';
import { formatTranslationStringOnClient } from '@shared/utils';

import { LearnContentCarouselCardImage } from './learn-content-carousel-card-image-with-number';
import styles from './learn-content-carousel-card.module.scss';

export type LearnContentCarouselCardProps = {
  card: LearnContentCarouselCardFragment;
  carouselTitle: string;
  cmsContent: LearnGenericTextsCmsContent;
  selectedFilterValue?: string | null;
  svgNumber?: number | undefined;
};

export const LearnContentCarouselCard = ({
  card,
  carouselTitle,
  cmsContent,
  selectedFilterValue,
  svgNumber,
}: LearnContentCarouselCardProps) => {
  const ContentTypeIconComponent = getContentTypeIconComponent(card?.ContentTypeSelection);
  const fallbackImageSource = getContentFallbackImageSource(card?.ContentTypeSelection);
  const imageAlt = card?.ThumbnailImage?.AltText;
  const people = getFeaturedPeopleOrAuthors(card);
  const firstAuthor = people[0];
  const otherAuthorsCount = firstAuthor && people.length > 1 ? people.length - 1 : undefined;
  const description = getCardLinkDescription(card?.SubheadingText, card?.Subheading);
  const locale = useLocale();
  const formattedUrl =
    locale === DEFAULT_LOCALE
      ? card?.RelativePath
      : card?.RelativePath?.replace(/^\/[\w-]+\//u, '/');
  const formattedReadTimeLabel =
    card.ReadTimeInSeconds && card.ReadTimeInSeconds > 0
      ? formatTranslationStringOnClient(cmsContent.minutesLabel, {
          readTime: convertSecondsToMinutes(card.ReadTimeInSeconds),
        })
      : null;

  return (
    <CardLink
      ImageComponent={
        <LearnContentCarouselCardImage
          alt={imageAlt ?? card?.Heading ?? ''}
          className={styles['learn-content-carousel-card__image']}
          fallbackSrc={fallbackImageSource}
          numberClassName={styles['learn-content-carousel-card__number']}
          numberTranslation={cmsContent.number}
          src={card?.ThumbnailImage?.ImageReference?.Url ?? fallbackImageSource}
          svgNumber={svgNumber}
        />
      }
      actionClassName={styles['learn-content-carousel-card__action']}
      className={styles['learn-content-carousel-card']}
      data-ga-carousel-filter={selectedFilterValue}
      data-ga-carousel-header={carouselTitle}
      scroll
      to={formattedUrl ?? undefined}
    >
      {ContentTypeIconComponent && (
        <Badge className={styles['learn-content-carousel-card__type-badge']}>
          <ContentTypeIconComponent className={styles['learn-content-carousel-card__type-icon']} />
        </Badge>
      )}
      {/* TODO: implementation in future */}
      {/* <BookmarkIcon className={styles['learn-content-carousel-card__bookmark-icon']} /> */}
      <div className={styles['learn-content-carousel-card__content']}>
        {card?.Heading && (
          <h3 className={styles['learn-content-carousel-card__title']} title={card.Heading}>
            {card.Heading}
          </h3>
        )}
        <div className={styles['learn-content-carousel-card__info']}>
          <p className={styles['learn-content-carousel-card__authors']} title={people.join('; ')}>
            <span className={styles['learn-content-carousel-card__first-author']}>
              {firstAuthor}
            </span>
            {otherAuthorsCount && (
              <span className={styles['learn-content-carousel-card__author-count']}>
                +{otherAuthorsCount}
              </span>
            )}
          </p>
          {formattedReadTimeLabel && (
            <span
              className={classnames(styles['learn-content-carousel-card__read-time'], {
                [styles['learn-content-carousel-card__read-time--with-separator']]: firstAuthor,
              })}
              title={formattedReadTimeLabel}
            >
              {formattedReadTimeLabel}
            </span>
          )}
        </div>
        <RenderHTML
          className={styles['learn-content-carousel-card__description']}
          html={description}
          title={getTextFromHtmlString(description)}
        />
      </div>
    </CardLink>
  );
};

export default LearnContentCarouselCard;
