import * as React from 'react';
import { type SVGProps } from 'react';

export const SvgNumber05 = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 123 80" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m77.064 76.524.008.002.009.002q7.309 2.12 14.734 2.12c6.073 0 11.355-1.024 15.816-3.11l.012-.006c4.453-2.155 7.929-5.188 10.394-9.103l.005-.007c2.466-3.989 3.685-8.578 3.685-13.734 0-4.857-1.036-9.156-3.143-12.864-2.031-3.697-4.904-6.57-8.601-8.601-3.706-2.105-7.969-3.143-12.76-3.143-3.683 0-7.03.658-10.024 1.992V19.68h29.848V2.184h-49.84v47.864h13.345l.285-.257c3.364-3.034 7.15-4.527 11.394-4.527 2.938 0 5.177.78 6.824 2.243l.008.007.008.007c1.625 1.385 2.456 3.243 2.456 5.687 0 2.51-.862 4.351-2.53 5.662-1.703 1.338-4.192 2.074-7.598 2.074-6.572 0-12.86-2.041-18.882-6.169l-1.023-.7-.469 1.147-5.824 14.248-.274.67.552.47c2.907 2.47 6.784 4.43 11.585 5.913ZM8.364 68.352l.003.004c4.996 6.878 12.249 10.292 21.609 10.292s16.613-3.414 21.61-10.292l.002-.004c4.953-6.891 7.364-16.557 7.364-28.872 0-12.242-2.41-21.745-7.374-28.366-5-6.737-12.251-10.074-21.602-10.074-9.35 0-16.602 3.337-21.602 10.074C3.41 17.736 1 27.274 1 39.584c0 12.247 2.412 21.878 7.364 28.768Zm28.085-11.708-.003.008c-.747 1.688-1.665 2.881-2.719 3.653-1.043.764-2.28 1.159-3.751 1.159s-2.719-.395-3.782-1.163c-1.072-.774-2.014-1.968-2.793-3.654-1.506-3.477-2.305-9.159-2.305-17.167 0-7.952.802-13.443 2.295-16.627.777-1.616 1.715-2.763 2.787-3.508 1.065-.739 2.318-1.121 3.798-1.121s2.722.382 3.766 1.118c1.053.741 1.967 1.885 2.71 3.5l.005.011.005.01c1.556 3.178 2.394 8.664 2.394 16.617 0 8.014-.835 13.693-2.407 17.164Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
