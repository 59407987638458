import { type PageMainContentFragment } from '@module/mdrt-org/shared/utils/data/graphql/_generated/gql-generated';
import { isNonNullable } from '@module/mdrt-org/shared/utils/helpers';

export const getPageCarouselBlockGuids = (pageBlocks: PageMainContentFragment[]) => {
  return pageBlocks
    .filter(
      (pageBlock) => pageBlock.ContentLink?.Expanded?.__typename === 'LearnCarouselContainerBlockV2'
    )
    .map((pageBlock) => pageBlock.ContentLink?.GuidValue)
    .filter(isNonNullable);
};
