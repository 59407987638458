import { type SVGProps } from 'react';

export const PlusesGraphics = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 172 172" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      clipRule="evenodd"
      d="M15 2h-2v11H2v2h11v11h2V15h11v-2H15zM63 2h-2v11H50v2h11v11h2V15h11v-2H63zM111 2h-2v11H98v2h11v11h2V15h11v-2h-11zM159 2h-2v11h-11v2h11v11h2V15h11v-2h-11zM15 50h-2v11H2v2h11v11h2V63h11v-2H15zM15 98h-2v11H2v2h11v11h2v-11h11v-2H15zM15 146h-2v11H2v2h11v11h2v-11h11v-2H15zM63 50h-2v11H50v2h11v11h2V63h11v-2H63z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M63 90h-2v19H42v2h19v19h2v-19h19v-2H63z"
      fill="#FFCF00"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M63 146h-2v11H50v2h11v11h2v-11h11v-2H63zM111 50h-2v11H98v2h11v11h2V63h11v-2h-11zM111 98h-2v11H98v2h11v11h2v-11h11v-2h-11zM111 146h-2v11H98v2h11v11h2v-11h11v-2h-11zM159 50h-2v11h-11v2h11v11h2V63h11v-2h-11zM159 98h-2v11h-11v2h11v11h2v-11h11v-2h-11zM159 146h-2v11h-11v2h11v11h2v-11h11v-2h-11z"
      fill="#8EE4F6"
      fillRule="evenodd"
    />
  </svg>
);
