import { useEffect, useState } from 'react';

export const useSessionStorageState = ({
  defaultValue,
  key,
}: {
  defaultValue?: string;
  key: string;
}): [string, (value: string) => void] => {
  const [innerState, setInnerState] = useState(defaultValue ?? '');

  const setSessionStorage = (value: string) => {
    setInnerState(value);
    window.sessionStorage.setItem(key, value);
  };

  useEffect(() => {
    const existingSessionValue = sessionStorage.getItem(key);
    if (existingSessionValue) {
      setInnerState(existingSessionValue);
    }

    if (!existingSessionValue && defaultValue) {
      sessionStorage.setItem(key, defaultValue);
    }
  }, [defaultValue, key, setInnerState]);

  return [innerState, setSessionStorage];
};
