import * as React from 'react';
import { type SVGProps } from 'react';

export const SvgNumber02 = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 122 81" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M67.311 77.328v1h52.96V60.832h-26.25l10.144-10.144c4.759-4.62 8.203-9.006 10.281-13.16l.003-.007c2.076-4.224 3.118-8.638 3.118-13.233 0-7.168-2.342-12.783-7.125-16.685-4.684-3.877-11.395-5.739-19.979-5.739a47.4 47.4 0 0 0-14.447 2.231l-.004.001c-4.6 1.487-8.557 3.513-11.853 6.092l-.604.473.3.706 5.928 13.936.501 1.18 1.022-.773c2.776-2.1 5.532-3.641 8.265-4.642 2.815-1.005 5.543-1.5 8.188-1.5 3.26 0 5.52.643 6.95 1.763 1.383 1.084 2.138 2.734 2.138 5.141 0 1.883-.531 3.795-1.638 5.747l-.006.011-.006.011c-1.05 1.969-2.889 4.39-5.566 7.273L67.588 62.597l-.277.29v14.440999999999999ZM8.364 69.176l.003.004c4.996 6.878 12.249 10.292 21.609 10.292s16.613-3.414 21.61-10.292l.002-.004c4.953-6.891 7.364-16.557 7.364-28.872 0-12.242-2.41-21.745-7.374-28.366-5-6.737-12.251-10.074-21.602-10.074-9.35 0-16.602 3.337-21.602 10.074C3.41 18.56 1 28.098 1 40.408c0 12.247 2.412 21.878 7.364 28.768Zm28.085-11.708-.003.007c-.747 1.689-1.665 2.882-2.719 3.654-1.043.765-2.28 1.159-3.751 1.159s-2.719-.394-3.782-1.163c-1.072-.774-2.014-1.968-2.793-3.654-1.506-3.477-2.305-9.159-2.305-17.167 0-7.952.802-13.443 2.295-16.627.777-1.616 1.715-2.763 2.787-3.508 1.065-.739 2.318-1.121 3.798-1.121s2.722.382 3.766 1.118c1.053.741 1.967 1.885 2.71 3.5l.005.011.005.01c1.556 3.178 2.394 8.663 2.394 16.617 0 8.014-.835 13.692-2.407 17.164Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
