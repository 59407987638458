import { useState } from 'react';

import { compareAlphabetically, uniqBy } from '@shared/utils';

import { type LearnContentCarouselCardFragment } from '../../../../../utils/data/graphql/_generated/gql-generated';
import { isNonNullable } from '../../../../../utils/helpers/type-compatible-filter-functions';

export type UseCarouselSourceFilterProps = {
  cards: LearnContentCarouselCardFragment[];
  selectedSourcesIds: number[];
  showSourcesFilter: boolean;
  sourcesFilterAllSelectionLabel: string | null | undefined;
};

export const useCarouselSourceFilter = ({
  cards,
  showSourcesFilter,
  selectedSourcesIds,
  sourcesFilterAllSelectionLabel,
}: UseCarouselSourceFilterProps) => {
  const [currentSourceIdFilter, setCurrentSourceIdFilter] = useState<number | null>(null);

  if (!showSourcesFilter) {
    return {
      filterCardsBySelectedSource: (cardsArgument: LearnContentCarouselCardFragment[]) =>
        cardsArgument,
      setCurrentSourceIdFilter,
      sourceFilterItems: [],
    };
  }

  const cardCategories = cards.flatMap((card) => card.ExpandedCategories)?.filter(isNonNullable);

  const cardUniqueSourceCategories = uniqBy(
    cardCategories.filter((category) => selectedSourcesIds.includes(category.Id ?? 0)),
    (category) => category.Id ?? ''
  )
    .filter((category) => category.Id)
    .sort((categoryA, categoryB) => compareAlphabetically(categoryA.Name, categoryB.Name));

  const sourceFilterItems = [
    {
      label: sourcesFilterAllSelectionLabel || 'All meetings',
      selected: currentSourceIdFilter === null,
      value: null,
    },
    ...cardUniqueSourceCategories.map((sourceCategory) => ({
      label: sourceCategory.Name,
      selected: sourceCategory.Id === currentSourceIdFilter,
      value: sourceCategory.Id ?? 0,
    })),
  ];

  return {
    filterCardsBySelectedSource: (cardsArgument: LearnContentCarouselCardFragment[]) =>
      currentSourceIdFilter === null
        ? cardsArgument
        : cardsArgument.filter((card) =>
            card.ExpandedCategories?.some((category) => category?.Id === currentSourceIdFilter)
          ),
    setCurrentSourceIdFilter,
    sourceFilterItems,
  };
};
