'use client';

import { useEffect } from 'react';

import { useSubNavigationContext } from '../../components/providers/sub-navigation-context';

export const BlockSubNavigation = () => {
  const { registerElements } = useSubNavigationContext();

  useEffect(() => {
    // informing the sub navigation context that blocks have been rendered
    registerElements();
  }, [registerElements]);

  return null;
};
