import { useState } from 'react';

import { uniqBy } from '@shared/utils';

import { type LearnContentCarouselCardFragment } from '../../../../../utils/data/graphql/_generated/gql-generated';
import { isNonNullable } from '../../../../../utils/helpers/type-compatible-filter-functions';
import { useMappedIntlLocale } from '../../../../../utils/hooks/use-mapped-intl-locale';

export type UseCarouselMagazineIssueFilterProps = {
  cards: LearnContentCarouselCardFragment[];
  showMagazineIssueFilter: boolean;
};

type MagazineIssueFilterValue = {
  month: number;
  year: number;
};

export const useCarouselMagazineIssueFilter = ({
  cards,
  showMagazineIssueFilter,
}: UseCarouselMagazineIssueFilterProps) => {
  const sortedMagazineIssues: MagazineIssueFilterValue[] = showMagazineIssueFilter
    ? uniqBy(
        cards
          .map((card) =>
            card.MagazineIssueYear && card.MagazineIssueMonth
              ? { month: card.MagazineIssueMonth, year: card.MagazineIssueYear }
              : null
          )
          .filter(isNonNullable),
        // uniqBy key
        (issue) => `${issue.year} ${issue.month}`
      ).sort((issue1, issue2) => {
        if (issue1.year !== issue2.year) {
          return issue2.year - issue1.year;
        }

        return issue2.month - issue1.month;
      })
    : [];

  const [currentMagazineIssueFilter, setCurrentMagazineIssueFilter] =
    useState<MagazineIssueFilterValue | null>(sortedMagazineIssues?.[0] ?? null);
  const locale = useMappedIntlLocale();

  const readableFormat = new Intl.DateTimeFormat(locale, {
    month: 'short',
    year: 'numeric',
  });

  const ariaFormat = new Intl.DateTimeFormat(locale, {
    month: 'long',
    year: 'numeric',
  });

  if (!showMagazineIssueFilter) {
    return {
      filterCardsBySelectedMagazineIssue: (cardsArgument: LearnContentCarouselCardFragment[]) =>
        cardsArgument,
      magazineIssueFilterDisplayedItems: [],
      magazineIssueFilterDropdownItems: [],
      setCurrentMagazineIssueFilter,
    };
  }

  const filterItems = sortedMagazineIssues.map((issue) => {
    const dateFrom = new Date(issue.year, issue.month - 1);
    const dateTo = new Date(issue.year, issue.month);

    return {
      ariaLabel: ariaFormat.formatRange(dateFrom, dateTo),
      label: readableFormat.formatRange(dateFrom, dateTo),
      selected:
        currentMagazineIssueFilter?.year === issue.year &&
        currentMagazineIssueFilter?.month === issue.month,
      value: issue,
    };
  });

  return {
    currentMagazineIssueFilter,
    filterCardsBySelectedMagazineIssue: (cardsArgument: LearnContentCarouselCardFragment[]) =>
      currentMagazineIssueFilter
        ? cardsArgument.filter(
            (card) =>
              card.MagazineIssueMonth === currentMagazineIssueFilter?.month &&
              card.MagazineIssueYear === currentMagazineIssueFilter?.year
          )
        : cardsArgument,
    magazineIssueFilterDisplayedItems: filterItems.slice(0, 2),
    magazineIssueFilterDropdownItems: filterItems.slice(2),
    setCurrentMagazineIssueFilter,
  };
};
